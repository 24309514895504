import type { ReactElement } from 'react'

import styled from 'styled-components'

const MAX_WIDTH = 1400

const MaxWidthContainer = ({
    children,
    centraliseChildren,
}: {
    children: ReactElement | ReactElement[]
    centraliseChildren?: boolean
}) => {
    return (
        <Container $centraliseChildren={centraliseChildren}>
            <MaxWidth>{children}</MaxWidth>
        </Container>
    )
}

// Be careful what you change here as it may interfere with other sticky components
const Container = styled.div<{ $centraliseChildren?: boolean }>`
    width: 100%;
    display: flex;

    justify-items: ${({ $centraliseChildren }) => (!!$centraliseChildren ? 'center' : 'unset')};
    justify-content: ${({ $centraliseChildren }) => (!!$centraliseChildren ? 'center' : 'unset')};
`

const MaxWidth = styled.div`
    width: 100%;
    max-width: ${MAX_WIDTH}px;

    @media (max-width: ${({ theme }) => theme.layout.breakpoints.xxLarge}) {
        max-width: 100vw;
    }
`

export default MaxWidthContainer
