/**
 *
 * Put the names and variant names of experiments in here. Means we can get a rough idea
 * of how many experiments we still have in the codebase
 *
 * NOTE: If you change any strings here...worth also searching for strings in the codebase as sometimes
 * have logic in cypress to mock these experiments
 *
 */

export const STRIPE_CHECKOUT_EXPERIMENT = {
    NAME: 'stripe_checkout_180624',
    VARIANT_DEFAULT: 'default',
    VARIANT_NEW_CHECKOUT: 'stripe_checkout',
}

export const PREMIUM_CONTENT_PREVIEW = {
    NAME: 'premium_content_preview_250624',
    VARIANTS: {
        DEFAULT: 'default' as const,
        PREMIUM_HIGHLIGHTED: 'premium_highlighted' as const,
        PREMIUM_CROWN: 'premium_crown' as const,
    },
}

export const ONBOARDING_EXPERIMENT = {
    NAME: 'onboarding_flow_web_190724',
    VARIANT_DEFAULT: 'default',
    VARIANT_ONBOARDING: 'onboarding_flow',
}

export const MENTIONED_ASSETS_PLACEMENT_EXPERIMENT = {
    NAME: 'mentioned_assets_placement',
    VARIANT_TOP: 'default',
    VARIANT_BOTTOM: 'bottom',
}
